import { sessionApiClient } from "@planningcenter/cc-api-client"
import { isChurchCenterApp } from "source/Layout"

export const PENDO_EVENTS = {
  get sermonNoteCreated() {
    return `Church Center ${isChurchCenterApp() ? "App" : "Web"} | Sermon Note created`
  },
}

export function trackPendoEvent(event) {
  sessionApiClient.post("/publishing/v2/track_pendo_event", {
    data: {
      attributes: {
        event,
        origin_url: window.location.href,
      },
    },
  })
}
