import { useState, useEffect } from "react"
import { css } from "@emotion/react"
import PropTypes from "prop-types"
import { Icon } from "source/shared/components"

Resources.propTypes = {
  episodeDescription: PropTypes.string,
  episodeResources: PropTypes.array,
  notesToggleButton: PropTypes.node,
  shouldOpenInChurchCenterModal: PropTypes.bool,
  showFullDescription: PropTypes.bool,
}

export default function Resources({
  episodeDescription,
  episodeResources,
  notesToggleButton,
  shouldOpenInChurchCenterModal,
  showFullDescription = false,
  ...restProps
}) {
  const icons = {
    generic_url: "fa-link",
    giving_fund: "fa-money-bill",
    people_form: "fa-user-plus",
    services_public_page: "fa-list-ul",
  }
  const defaultIcon = "fa-link"

  const [descriptionExpanded, setDescriptionExpanded] = useState(
    episodeResources.length === 0,
  )

  const [truncatedEpisodeDescription, setTruncatedEpisodeDescription] =
    useState("")

  useEffect(() => {
    if (!episodeDescription) return
    let truncated = episodeDescription
    // strategy 1: by words
    const words = episodeDescription.split(" ")
    if (words.length > 35) {
      truncated = words.slice(0, 25).join(" ") + "..."
    }
    // strategy 2: by lines
    const lines = truncated.split("\n")
    if (lines.length > 4) {
      truncated = lines.slice(0, 3).join("\n") + "..."
    }
    setTruncatedEpisodeDescription(truncated)
  }, [episodeDescription])

  const shouldShowMoreButton =
    !showFullDescription &&
    episodeDescription !== truncatedEpisodeDescription &&
    episodeResources.length > 0

  return (
    <div {...restProps}>
      {episodeDescription && (
        <div data-name="episode summary" className="action-drawer mb-2">
          <p className="mb-0" css={{ whiteSpace: "pre-line" }}>
            <span>
              {descriptionExpanded || showFullDescription
                ? episodeDescription
                : truncatedEpisodeDescription}
            </span>
            {shouldShowMoreButton && (
              <button
                style={{ display: "block", height: "auto" }}
                className="btn link-btn mt-1"
                onClick={() => setDescriptionExpanded(!descriptionExpanded)}
              >
                {descriptionExpanded ? "Show Less" : "Show More"}
              </button>
            )}
          </p>
        </div>
      )}
      {notesToggleButton}
      {episodeResources.map((resource) => (
        <div key={resource.id} className="mb-1">
          <a
            href={urlForResource(resource)}
            className="d-f ai-c jc-sb c-tint2 fs-3"
            data-open-in-church-center-modal={shouldOpenInChurchCenterModal.toString()}
            css={styles.resourceLink}
          >
            <span className="mr-2">
              {/* icons from general: bill, link, outlined-notes, plus-person  */}
              <Icon
                symbol={
                  (resource.attributes.icon &&
                    `fa-${resource.attributes.icon}`) ||
                  icons[resource.attributes.kind] ||
                  defaultIcon
                }
                aria-hidden
              />
            </span>
            <span className="f-1">{resource.attributes.title}</span>
            <Icon
              css={styles.resourceChevron}
              symbol="general#right-chevron"
              aria-hidden
            />
          </a>
        </div>
      ))}
    </div>
  )
}

const styles = {
  resourceLink: css`
    border: 1px solid var(--color-tint6);
    padding: 0.5rem 1rem;
    border-radius: 4px;
    &:hover {
      background-color: var(--color-tint6);
    }
  `,
  resourceChevron: css`
    width: 12px;
    height: 12px;
    color: var(--color-tint3);
  `,
}

export function urlForResource(resource) {
  const KINDS_TO_APPEND_SOURCE_CCA = ["giving_fund", "people_form"]
  if (KINDS_TO_APPEND_SOURCE_CCA.indexOf(resource.attributes.kind) > -1) {
    // We want no header and no footer, and `?source=cca` is the way to signal that.
    return `${resource.attributes.url}?source=cca`
  }
  return resource.attributes.url
}
